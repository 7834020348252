import React from 'react';
import { useEffect, useState } from 'react';

export default function Module(props) {
	const [moduleData, setModuleData] = useState([]);
	const iconPath = '/icons/' + props.icon;

	useEffect(() => {
		var contentful = require('contentful');
		var websiteSpace = contentful.createClient({
			//TODO: Store these in environment variables
			space: process.env.REACT_APP_WEBSITE_SPACE,
			accessToken: process.env.REACT_APP_WEBSITE_TOKEN,
		});
		var tempArray = [];
		websiteSpace.getEntries().then(function(entries) {
			// log the title for all the entries that have it
			entries.items.forEach(function(entry) {
				var data = entry.fields;
				var tempSite = {
					title: data.title,
					link: data.link,
					description: data.description,
					tags: data.tags,
				};

				if (props.filter !== '') {
					if (props.tag instanceof Array) {
						for (let i = 0; i < props.tag.length; i++) {
							if (
								tempSite.tags.includes(props.tag[i]) &&
								tempSite.tags.includes(props.filter)
							) {
								tempArray.push(tempSite);
								break;
							}
						}
					} else if (
						tempSite.tags.includes(props.tag) &&
						tempSite.tags.includes(props.filter)
					) {
						tempArray.push(tempSite);
					} else {
						return;
					}
				} else {
					// Filter not selected
					if (props.tag instanceof Array) {
						for (let i = 0; i < props.tag.length; i++) {
							if (tempSite.tags.includes(props.tag[i])) {
								tempArray.push(tempSite);
								break;
							}
						}
					} else if (tempSite.tags.includes(props.tag)) {
						tempArray.push(tempSite);
					} else {
						return;
					}
				}
			});

			setModuleData(tempArray);
		});
	}, [props.filter, props.tag]);

	function sortModuleData() {
		moduleData.sort(function(a, b) {
			var nameA = a.title.toUpperCase();
			var nameB = b.title.toUpperCase();

			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
	}

	return (
		<div className="content-module">
			<h1 className="section-title">{props.moduleName}</h1>
			<ul>
				{moduleData.length ? (
					(sortModuleData(),
					moduleData.map((s, i) => {
						return (
							<li
								key={i}
								onClick={() => {
									window.open(s.link.toString(), '_blank');
								}}
							>
								<div className="listing-group">
									<h3>{s.title}</h3>
									<p className="item-url">{s.link}</p>
									<p>{s.description}</p>
								</div>
								<img
									className="icon"
									src={iconPath}
									alt={props.altText}
								></img>
							</li>
						);
					}))
				) : (
					<>
						<ul>
							<li>
								<div className="listing-group">
									<h3>No results.</h3>
									<p className="item-url">
										https://fpsspot.com/
									</p>
									<p>Try adjusting the filter</p>
								</div>
								<img
									className="icon"
									src={iconPath}
									alt={props.altText}
								></img>
							</li>
						</ul>
					</>
				)}
			</ul>
		</div>
	);
}
