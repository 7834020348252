import './App.css';
import Module from './components/Module';
import { useState } from 'react';

function App() {
	const [filter, setFilter] = useState('');

	function filterModules(e) {
		setFilter(e.target.value);
	}
	return (
		<>
			<div className="navbar">
				<h1>FPS Spot</h1>
				<p>Your FPS Directory</p>
				<label htmlFor="tag">Filter by game:</label>
				<form>
					<select
						name="tags"
						id="tags"
						onChange={(e) => filterModules(e)}
					>
						<option value="">All</option>
						<option value="valorant">Valorant</option>
						<option value="csgo">CS:GO</option>
						<option value="cod">Call of Duty</option>
					</select>
				</form>
			</div>
			<div className="content-container">
				<Module
					moduleName="Improvement"
					icon="book.svg"
					tag="improvement"
					filter={filter}
					altText="Improvement Icon"
				/>
				<Module
					moduleName="Resources"
					icon="link.svg"
					tag="resource"
					filter={filter}
					altText="Resources Icon"
				/>
				<Module
					moduleName="Tools"
					icon="tool.svg"
					tag="tool"
					filter={filter}
					altText="Tools Icon"
				/>
			</div>
		</>
	);
}

export default App;
